import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Experience from './components/experience/Experience';
import Services from './components/services/Services';
import Portfolio from './components/portfolio/Portfolio';
import Contact2 from './components/contact/Contact2';
import Footer from './components/footer/Footer';
import DataProtection from './components/data_protection/DataProtection';
import Imprint from './components/imprint/Imprint';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <Nav />
              <About />
              <Experience />
              <Services />
              <Portfolio />
              {/*<Contact />*/}
              <Contact2 />
              <Footer />
            </>
          } />
          <Route path="/datenschutz" element={<DataProtection />} />
          <Route path="/impressum" element={<Imprint />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
