import React from 'react'
import './about.css'
import ProfilPictureTwo from '../../assets/Portfolio_Bild_2.jpg'
import { FaAward, FaBriefcase } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'

const About = () => {
  return (
    <section id='about'>
      <h5>Lernen Sie mich kennen</h5>
      <h2>Über mich</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ProfilPictureTwo} alt="Über Mich Profilbild" />
          </div>
        </div>
        <div className="about__content">
          <p>
          Als passionierter Full-Stack-Entwickler und IT-Berater verfüge ich über umfassende Erfahrungen in der Entwicklung und dem Betrieb von Softwarelösungen, die reale Probleme adressieren. Meine Expertise erstreckt sich von der Gestaltung intuitiver Benutzeroberflächen bis hin zur Implementierung robuster Backend-Systeme und der nahtlosen Integration in Cloud-Infrastrukturen. Durch meine Rollen bei führenden Unternehmen wie Allianz Deutschland AG und Eviden Germany GmbH habe ich tiefgreifende Kenntnisse in der Schnittstellenentwicklung und im Umgang mit Big-Data-Projekten, insbesondere mit Graphdatenbanken, erworben. Mein Antrieb ist die kreative Zusammenarbeit in Teams, um durch technologische Innovationen echten Mehrwert zu schaffen.
          </p>
          <a href='#contact' className='btn btn-primary'>Kontakt aufnehmen</a>
        </div>
      </div>
    </section>
  )
}

export default About