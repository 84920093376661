import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio_item_1.png'
import IMG2 from '../../assets/portfolio_item_2.png'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>Einige Website Projekte</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG2} alt="Portfolio Image 2" />
          </div>
          <h3>Tavrix</h3>
          <div className="portfolio__item-cta">
            <a href="https://tavrix.de/" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG1} alt="Portfolio Image 1" />
          </div>
          <h3>N. S. Technik</h3>
          <div className="portfolio__item-cta">
            <a href="https://netzservicetechnik.de/" className='btn btn-primary' target='_blank'>Live Demo</a>
          </div>
        </article>
      </div>
    </section>
  )
}

export default Portfolio
