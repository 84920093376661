import React from 'react'
import './imprint.css'

const Imprint = () => {
  return (
    <section className="imprint__section"  id='imprint'>
      <div className="container imprint__container">
        <a href='/' className='btn btn-primary'>Zurück zur Homepage</a>
        <h1>Impressum</h1>

        <p>Waleed Ahmed<br />
          Oppenheimer Str. 2<br />
          70499 Stuttgart</p>

        <h2>Kontakt</h2>
        <p>Telefon: 017641744178<br />
          E-Mail: waleed@waleed-ahmed.de</p>

        <h2>Umsatzsteuer-ID</h2>
        <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
          DE365795369</p>

        <h2>EU-Streitschlichtung</h2>
        <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

        <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
        <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

        <p>Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a></p>
        <a href='/' className='btn btn-primary'>Zurück zur Homepage</a>
      </div>
    </section>
  )
}

export default Imprint