import React from 'react'
import './experience.css'
import { MdVerified } from 'react-icons/md'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>Fähigkeiten und Skills</h5>
      <h2>Meine Erfahrung</h2>

      <div className="container experience__container">
        {/* Frontend */}
        <div className="experience__frontend">
          <h3>Frontend Entwicklung</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>HTML</h4>
                <small className='text-light'>Forgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experte</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Bootstrap</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>React</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Angular</h4>
                <small className='text-light'>Grundkenntnisse</small>
              </div>
            </article>
          </div>
        </div>

        {/* Backend */}
        <div className="experience__backend">
          <h3>Backend Entwicklung</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Java</h4>
                <small className='text-light'>Experte</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Quarkus</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Spring</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Flask</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>NodeJS</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Python</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
          </div>
        </div>

        {/* DevOps */}
        <div className="experience__devops">
          <h3>Cloud Engineering</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Kubernetes</h4>
                <small className='text-light'>Forgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Docker</h4>
                <small className='text-light'>Experte</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Jenkins</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Google Cloud Plattform</h4>
                <small className='text-light'>Fortgeschritten</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>GitHub Actions</h4>
                <small className='text-light'>Experte</small>
              </div>
            </article>
            <article className='experience__details'>
              <MdVerified className='experience__details-icon' />
              <div>
                <h4>Terraform</h4>
                <small className='text-light'>Grundkenntnisse</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience