import React from 'react'
import './contact2.css'
import {HiOutlineMail} from 'react-icons/hi'
import {BsTelephone} from 'react-icons/bs'

const Contact = () => {
  return (
    <section id='contact'>
      <h5>Nehmen Sie Kontakt auf</h5>
      <h2>Kontakt</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <HiOutlineMail className='contact__option-icon' />
            <h4>E-Mail</h4>
            <h5>waleed@waleed-ahmed.de</h5>
            <a href="mailto:waleed@waleed-ahmed.de" target='_blank'>Schreiben Sie mir eine E-Mail</a>
          </article>
          <article className='contact__option'>
            <BsTelephone className='contact__option-icon' />
            <h4>Telefon</h4>
            <h5>+49 176 417 44 178</h5>
            <a href="tel:+4917641744178" target='_blank'>Rufen Sie mich an</a>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Contact