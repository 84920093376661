import React from 'react'
import './services.css'
import { BsCheck2 } from 'react-icons/bs'

const Services = () => {
  return (
    <section id='services'>
      <h5>Was ich anbiete</h5>
      <h2>Dienstleistungen</h2>

      <div className="container services__container">
        {/* Web Entwicklung */}
        <article className='service'>
          <div className="service__head">
            <h3>Webseiten Entwicklung</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Erstellung einer Landingpage</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Erstellung eines Web- / E-Commerce-Shop</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Anforderungsanalyse</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Designentwurf</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Kostenschätzung</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Betrieb, Wartung und Optimierung</p>
            </li>
          </ul>
        </article>

        {/* Backend Entwicklung */}
        <article className='service'>
          <div className="service__head">
            <h3>Web App Entwicklung</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Anforderungsanalyse</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Design- & Architekturentwurf</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Kostenschätzung</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Testen & Qualitätssicherung</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Betrieb, Wartung und Optimierung</p>
            </li>
          </ul>
        </article>

        {/* Cloud Entwicklung */}
        <article className='service'>
          <div className="service__head">
            <h3>Cloud Transformation & Betrieb</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Clouderweiterung bestimmter Dienste</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Cloudoptimierung</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Cloud-native Entwicklung</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>SaaS Ersetzung</p>
            </li>
            <li>
              <BsCheck2 className='service__list-icon' />
              <p>Betrieb, Wartung und Optimierung bestehender Cloud Lösung</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services