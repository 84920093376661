import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>WALEED AHMED</a>

      <ul className='permalinks'>
        <li><a href="/impressum">Impressum</a></li>
        <li><a href="/datenschutz">Datenschutzerklärung</a></li>
      </ul>

      <div className="footer__copyright">
        <small>&copy; Waleed Ahmed. Alle Rechte sind vorbehalten.</small><br />
        <small><a href="https://www.freepik.com/free-vector/stylish-hexagonal-line-pattern-background_5001147.htm#query=pattern&position=2&from_view=keyword&track=sph">Image by starline</a> on Freepik</small>
      </div>

      <div></div>
    </footer>
  )
}

export default Footer